.lost-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .scroll-container {
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h2 {
    font-family: 'Gotham Black';
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 0px rgb(6 6 6 / 20%);
    color: #fff;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 2rem;
    }
  }

  p {
    font-family: 'Ocra';
    text-transform: uppercase;
    font-size: 1rem;
    text-shadow: 0px 0px 16px rgb(6 6 6 / 20%);
    margin: 0;
    color: #40158f;
    z-index: 0;
  }

  section {
    display: flex;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .section-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 600px;
    width: auto;

    @media only screen and (max-width: 600px) {
      height: 300px;
    }
  }

  .video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
