.slideshow-wrapper2 {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content: space-evenly;
}

.image-slot2 {
  width: 33.333%;
  height: 100%;
  overflow: hidden;
  position: absolute; /* Overlay slots for transitioning */

  &:nth-of-type(3n + 1),
  &:nth-of-type(3n + 4) {
    left: 0;
  }

  &:nth-of-type(3n + 2),
  &:nth-of-type(3n + 5) {
    left: 33.33%;
  }

  &:nth-of-type(3n + 3),
  &:nth-of-type(3n + 6) {
    left: 66.66%;
  }
}

.image-slot2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Initial state for active slots */
.image-slot2 {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

/* Transition state for next slots */
.slideshow-wrapper2 .next {
  opacity: 1; /* Start hidden and fade in */
}

/* Adjust this as needed to trigger the fade-in effect */
.slideshow-wrapper2.load-next-set .fade-in {
  opacity: 0;
}
